import React, { Component } from "react";
import {
    Container,
    Box,
    CardContent,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Tabs,
    Tab,
    TextField,
    FormHelperText,
    Grid,
    FormControlLabel,
    CircularProgress,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Sidebar from "../../../components/src/Sidebar.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import {Formik } from "formik";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import { activity, quantityOnHand, quantityPurchased, quantitySold ,addMedia } from "./assets";
import AccountingTab from "./AccountingTab.web";
import SalesTab from "./SalesTab.web";
import PurchaseTab from "./PurchaseTab.web";
import GeneralInfoTab from "./GeneralInfoTab.web";
import InventoryTab from "./InventoryTab.web";
const { alert } = require("./assets");
import { ToastContainer } from 'react-toastify'

// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        },
        secondary: {
            main: "#2A6396",
            contrastText: "#fff"
        }
    }
});

export const PRODUCT_CARD_DATA = [
    {
        value: 800,
        prodStatus: "Quantity on Hand",
        icon: quantityOnHand,
    },
    {
        value: 700,
        prodStatus: "Quantity Sold",
        icon: quantitySold,
    },
    {
        value: 1500,
        prodStatus: "Quantity Purchased",
        icon: quantityPurchased,
    },
];

export class TabContainer extends Component {
    render() {
        return (
            <Box component="div" style={{ paddingBottom: 0, paddingLeft: 0 }}>
                {this.props.children}
            </Box>
        );
    }
}


// Customizable Area End

import ItemavailabilityController, { Props } from "./ItemavailabilityController";

export default class ProductCreation extends ItemavailabilityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    
    // Customizable Area Start
    productDataCard = (item: any) => {
        return (
            <Box sx={webStyle.productStatusCard}>
                <Box>
                    <img src={item.icon} alt={"icon"} />
                </Box>
                <Box>
                    <Typography>
                        {item.value}
                        <span style={{ fontSize: 10, marginLeft: 2 }}>Units</span>
                    </Typography>
                    <Typography>{item.prodStatus}</Typography>
                </Box>
            </Box>
        );
    };
    renderProductTitle  = ()=>{
        return(
            this.state.isGeneraltabEditing?(<><Typography data-testId="productLink" variant="h5" style={webStyle.titleTypo2} onClick={this.navigatedToProduct}>
                Product /{" "}
            </Typography><Typography variant="h5" style={webStyle.titleTypo}>
                    New
                </Typography></>):(<Typography variant="h5" style={webStyle.titleTypo2}>
            Product 
        </Typography>)
        )
    }

    productImgSelection=()=>{
        return(
            <Box className={`${(!this.state.productImage) && 'imageBox'}`} style={{cursor:"pointer"}}>
                                                    <img data-test-id="productImageRef" className='br-5' onClick={() => { this.productImageChange() }} src={typeof(this.state.productImage)==="string"? this.state.productImage : this.chooseImage()} height={100} width={100} />
                                                </Box>
        )
    }

    editStatus=()=>{
        return(this.state.isGeneraltabEditing || this.state.isSalesEditing || this.state.isPurchaseEditing||
            this.state.isInventoryEditing || this.state.isAccountingEditing)
    }
    chooseImage=()=>{
        return(
            this.state.productImage ? URL.createObjectURL(this.state.productImage) : addMedia
        )
    }

    renderProductCreation=()=>{
        return(
<Formik
                    initialValues={this.getInitialValues()}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {console.log(values,"values-------")}}
                >
                    {({ errors, touched, handleChange, values,resetForm, handleBlur, handleSubmit, setFieldValue  }) => {
                        return (
                            
                                <Container maxWidth={false} style={webStyle.mainConatiner as React.CSSProperties}>
                                    <Box>
                                        <AppHeader pageTitle={"Product"} />
                                    </Box> 
                                    
                                    <Box sx={webStyle.productContainer}>
                                        <Box sx={webStyle.profileBlock}>
                                            <Box sx={webStyle.title}>
                                                {this.renderProductTitle()}
                                            </Box>
                                            <Box sx={webStyle.productCardBox}>
                                                <Box sx={webStyle.productStatus}>
                                                    {PRODUCT_CARD_DATA.map((item, id) => (
                                                        <Box key={id} style={webStyle.productSubBox}>
                                                            {this.productDataCard(item)}
                                                            {id !== 2 && <Box sx={webStyle.verticalLine}></Box>}
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <Box sx={webStyle.activityIconWrapper}>
                                                    <Box>
                                                        <img src={activity} alt={"icon"} />
                                                    </Box>
                                                    <Typography>Activity</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <style>
                                            {`
                                                & .MuiFormControl-root {
                                                    margin-bottom: 0 !important;
                                                }
                                                .MuiTab-textColorSecondary.Mui-selected {
                                                    font-weight: bold;
                                                }
                                            `}
                                        </style>
                                        {this.editStatus() ? (<Box sx={webStyle.productProfileWrapper}>
                                            <Box sx={webStyle.productNameType}>
                                                <Box>
                                                    <Typography variant="h5">New</Typography>
                                                </Box>
                                                <Box sx={webStyle.productNameField}>
                                                    <Typography style={webStyle.pageHeading as React.CSSProperties}>
                                                        Product Name<span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                    <div style={webStyle.formGroupContainer as React.CSSProperties}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            type="text"
                                                            data-testid="productNameField"
                                                            placeholder="Enter Product Name"
                                                            value={this.state.product_name}
                                                            onChange={(e) => this.handleProductNameChange(e)}
                                                            error={Boolean(this.state.product_name_error)}
                                                            onBlur={this.handleProductNameBlur}
                                                        />
                                                        <div style={webStyle.errorBox}>
                                                            {this.state.product_name_error && <img src={alert} style={webStyle.alertIcon as React.CSSProperties} />}
                                                            <FormHelperText style={webStyle.errorTextMsg}>{this.state.product_name_error}</FormHelperText>
                                                        </div>
                                                    </div>
                                                </Box>
                                                <Box sx={webStyle.productCheckbox}>
                                                    <Box sx={webStyle.checkboxField}>
                                                        <div className="form-group form-check" style={webStyle.checkboxDiv}>
                                                            <Checkbox
                                                                data-test-id="canbesold"
                                                                disableFocusRipple
                                                                disableRipple
                                                                disableTouchRipple
                                                                name={"canbesold"}
                                                                value={values["canbesold"]}
                                                                checked={values["canbesold"]}
                                                                className={
                                                                    'form-check-input' +
                                                                    (errors["canbesold"] && touched["canbesold"]
                                                                        ? ' is-invalid'
                                                                        : '')
                                                                }
                                                                onChange={handleChange}
                                                                style={{ padding: 0 }}
                                                            />
                                                            <Typography className="form-check-label">
                                                                Can be Sold
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                    <Box sx={webStyle.checkboxField}>
                                                        <div className="form-group form-check" style={webStyle.checkboxDiv}>
                                                            <Checkbox
                                                                data-test-id="canbepurchased"
                                                                disableFocusRipple
                                                                disableRipple
                                                                disableTouchRipple
                                                                value={values["canbepurchased"]}
                                                                name={"canbepurchased"}
                                                                checked={values["canbepurchased"]}
                                                                className={
                                                                    'form-check-input' +
                                                                    (errors["canbepurchased"] && touched["canbepurchased"]
                                                                        ? ' is-invalid'
                                                                        : '')
                                                                }
                                                                onChange={handleChange}
                                                                style={{ padding: 0 }}
                                                            />
                                                            <Typography className="form-check-label">
                                                                Can be purchased
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Grid item sm={2} className='end-center-align'>
                                                {this.productImgSelection()}
                                                <input data-test-id="imageFile" accept=".jpg, .jpeg, .png" type='file' className="display-none" ref={this.productImageRef} name="image" onChange={(e: any) => this.productChangeField(e)} />
                                            </Grid>
                                        </Box>) : 
                                        this.renderProductDetails()
                                        }
                                        <Box>
                                            <Tabs 
                                                indicatorColor="primary"
                                                data-test-id="productTabs"                                                
                                                textColor="secondary"
                                                value={this.state.selectedTab}
                                                onChange={(_, value) => this.handleTabChange(value)}
                                            >
                                                <Tab label="General Info" />
                                                <Tab label="Sales" />
                                                <Tab label="Purchase" />
                                                <Tab label="Inventory" />
                                                <Tab label="Accounting" />
                                            </Tabs>
                                            <div>
                                                {this.state.selectedTab === 0 && (
                                                    <Box sx={webStyle.tabContainer}>
                                                        <GeneralInfoTab
                                                            {...this.props}
                                                            data-test-id="generalInfoTab"
                                                            isGeneraltabEditing={this.state.isGeneraltabEditing}
                                                            selectedProductId={this.state.productId}
                                                            setAllFormOpen={this.setAllFormOpen}
                                                            navigateToCreateNewProduct={this.navigateToCreateNewProduct}
                                                            completeProductData={this.state.completeProductData}
                                                            uomCategoriesData={this.state.uomCategoriesData}
                                                            ProductCategoriesData={this.state.productCategoriesData}
                                                            getInitialValues={this.getInitialValues}
                                                            validationSchema={this.validationSchema}
                                                            extractUomValues={this.extractUomValues}
                                                            extractProductCategoryValues={this.extractProductCategoryValues}
                                                            handleSave={this.handleSave}
                                                            validateInput={this.validateInput}
                                                            productName={this.state.product_name}
                                                            errors={errors}
                                                            touched={touched}
                                                            values={values}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            handleSubmit={handleSubmit}
                                                            setFieldValue={setFieldValue}
                                                            resetForm={resetForm}
                                                            handleDiscardImage={this.handleDiscardImageAndName}
                                                        />
                                                    </Box>
                                                )}
                                                {this.state.selectedTab === 1 && (
                                                    <Box sx={webStyle.tabContainer}>
                                                        <SalesTab {...this.props}
                                                            data-test-id="salesTab"
                                                            isSalesEditing={this.state.isSalesEditing}
                                                            getInitialValues={this.getInitialValues}
                                                            setAllFormOpen={this.setAllFormOpen}
                                                            navigateToCreateNewProduct={this.navigateToCreateNewProduct}
                                                            selectedProductId={this.state.productId}
                                                            completeProductData={this.state.completeProductData}
                                                            validationSchema={this.validationSchema}
                                                            handleSave={this.handleSave}
                                                            productImage={this.state.productImage}
                                                            validateInput={this.validateInput}
                                                            extra_product_media={this.state.sales.extra_product_media}
                                                            mediaRef={this.extraMediaRef}
                                                            extraMediaChange={this.extraMediaChange}
                                                            productImageError={this.state.productImageError}
                                                            errors={errors}
                                                            touched={touched}
                                                            values={values}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            handleSubmit={handleSubmit}
                                                            setFieldValue={setFieldValue}
                                                            extractVendorTax={this.extractVendorTax}
                                                            onDeletePurchase={this.onDeletePurchase}
                                                            handleDialogCancel={this.handleDialogCancel}
                                                            handleAddRow={this.handleAddRow}
                                                            handleDeleteRow={this.handleDeleteRow}
                                                            resetForm={resetForm}
                                                            handleDiscardImage={this.handleDiscardImageAndName}
                                                            optionalProductOption={this.state.optionalProductOption}
                                                            accessoryProductOption={this.state.accessoryProductOption}
                                                            alernativeProductOption={this.state.alernativeProductOption}
                                                        />
                                                    </Box>
                                                )}
                                                {this.state.selectedTab === 2 && (
                                                    <PurchaseTab {...this.props} 
                                                        data-test-id="purchaseTab"
                                                        isPurchaseEditing={this.state.isPurchaseEditing}
                                                        getInitialValues={this.getInitialValues}
                                                        selectedProductId={this.state.productId}
                                                        navigateToCreateNewProduct={this.navigateToCreateNewProduct}
                                                        setAllFormOpen={this.setAllFormOpen}
                                                        completeProductData={this.state.completeProductData}
                                                        validationSchema={this.validationSchema}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        validateInput={this.validateInput}
                                                        handleSave={this.handleSave}
                                                        handleSubmit={handleSubmit}
                                                        handleChange={handleChange}
                                                        vendorNameList={this.state.vendorNameList}
                                                        vendorsListTableData={this.state.vendorsListTableData}
                                                        handleVendorsListResponse={this.handleVendorsListResponse}
                                                        handleAddRow={this.handleAddRow}
                                                        handleDeleteRow={this.handleDeleteRow}
                                                        productName={this.state.product_name}
                                                        onDeletePurchase={this.onDeletePurchase}
                                                        deleteDialog={this.state.deleteDialog}
                                                        handleDialogCancel={this.handleDialogCancel}
                                                        resetForm={resetForm}
                                                        handleDiscardImage={this.handleDiscardImageAndName}
                                                        errors={errors}
                                                        touched={touched}
                                                        vendorTaxOption={this.state.vendorTaxOption}
                                                    />
                                                )}
                                                {this.state.selectedTab === 3 && (
                                                    <Box sx={webStyle.tabContainer}>
                                                        <InventoryTab {...this.props}
                                                            data-test-id="inventoryTab"
                                                            userListData={this.state.userListData}
                                                            extractUserName={this.extractUserName}
                                                            isInventoryEditing={this.state.isInventoryEditing}
                                                            setAllFormOpen={this.setAllFormOpen}
                                                            getInitialValues={this.getInitialValues}
                                                            completeProductData={this.state.completeProductData}
                                                            navigateToCreateNewProduct={this.navigateToCreateNewProduct}
                                                            selectedProductId={this.state.productId}
                                                            handleSave={this.handleSave}
                                                            validationSchema={this.validationSchema}
                                                            productName={this.state.product_name}
                                                            handleDiscardImage={this.handleDiscardImageAndName}
                                                            handleSubmit={handleSubmit}
                                                            validateInput={this.validateInput}
                                                            touched={touched}
                                                            errors={errors}
                                                            handleChange={handleChange}
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            handleBlur={handleBlur}
                                                            resetForm={resetForm}
                                                        />
                                                    </Box>
                                                )}
                                                {this.state.selectedTab === 4 && (
                                                    <AccountingTab {...this.props}
                                                        data-test-id="accountingTab"
                                                        isAccountingEditing={this.state.isAccountingEditing}
                                                        getInitialValues={this.getInitialValues}
                                                        setAllFormOpen={this.setAllFormOpen}
                                                        selectedProductId={this.state.productId}
                                                        navigateToCreateNewProduct={this.navigateToCreateNewProduct}
                                                        completeProductData={this.state.completeProductData}
                                                        validationSchema={this.validationSchema}
                                                        handleSave={this.handleSave}
                                                        validateInput={this.validateInput}
                                                        productName={this.state.product_name}
                                                        errors={errors}
                                                        touched={touched}
                                                        values={values}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        setFieldValue={setFieldValue}
                                                        handleSubmit={handleSubmit}
                                                        resetForm={resetForm}
                                                        handleDiscardImage={this.handleDiscardImageAndName}
                                                    />
                                                )}
                                            </div>
                                        </Box>
                                    </Box>
                                </Container>
                           
                        )
                    }}
                </Formik>
        )
    }

    renderProductDetails = () =>{
        return(
            this.state.completeProductData && (this.state.completeProductData as any[]).length > 0 ? (
                (this.state.completeProductData as any[]).map((item: any) => {
                    if (item.id === this.state.productId) {
                        const { product_name, can_be_sold, can_be_purchased } = item.attributes;
                        const imgUrl = item.attributes.product_image?item.attributes.product_image.url:addMedia
                        return (<ProductTopDetails>
                        <ProductTopSubDetails>
                            <ProductTopSubDetailsBox>
                                <ProductNameTextContainer>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            Product Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">{product_name}</Typography>
                                    </Grid>
                                </ProductNameTextContainer>
                                <FormControlLabelContainer>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox checked={can_be_sold} />}
                                            label="Can be Sold"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            style={{ marginLeft: "0px" }}
                                            control={<Checkbox checked={can_be_purchased} />}
                                            label="Can be Purchased"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                </FormControlLabelContainer>
                            </ProductTopSubDetailsBox>
                            <AddMediaContainer>
                            <img src={imgUrl} alt="add-media" style={{ width: '150px', height: 'auto' }} />
                            </AddMediaContainer>
                        </ProductTopSubDetails>
                    </ProductTopDetails> );
                    }
                    return null;
                })
            ) : (
                <LoaderContainer>
                    <CircularProgress style={{color: "#2A6395"}}/>
                </LoaderContainer>
            )
        )
    }


    // Customizable Area End


    render() {
        // Customizable Area Start
        return (
            
            <ThemeProvider theme={theme}>

                    <ToastContainer />
                <Box display={"flex"}>
                                <Box>
                                    <Sidebar />
                                </Box>
                              
                    {this.state.loading?<LoaderContainer2>
                    <CircularProgress style={{color: "#2A6395"}}/>
                </LoaderContainer2>:
                    this.renderProductCreation()
                }
                </Box>
            </ThemeProvider>

        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    productContainer: {
        backgroundColor: "white",
        border: "1.5px solid lightgrey",
        borderRadius: 6
    },
    mainConatiner: {
        overflowX: "auto",
    },
    actionButtonBox: {
        marginTop: 12,
        marginBottom: 12
    },
    profileBlock: {
        height: 60,
        display: "flex",
        paddingInline: 16,
        justifyContent: "space-between",
        paddingBlock: 12,
        color: "#332f2f",
        borderBottom: "1px solid lightgrey",
    },
    title: {
        display:"flex",
        alignItem:"center"
    },
    productStatus: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        height: "100%",
    },
    productStatusCard: {
        marginRight: 8,
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    activityIconWrapper: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    verticalLine: {
        height: "100%",
        width: 1,
        backgroundColor: "#332f2f",
    },
    titleTypo2: {
        paddingTop: 12,
        color: "#005e95", 
        fontWeight: 600,
        cursor:"pointer"
    },
    titleTypo:{
        paddingTop:12
    },
    productSubBox: {
        height: "100%",
        display: "flex"
    },
    productCardBox: {
        display: "flex",
        gap: 40
    },
    productProfileWrapper: {
        display: "flex",
        justifyContent: "space-between",
        paddingInline: 16,
        paddingBlock: 12,
    },
    productNameType: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "baseline",
        width: "80%"
    },
    checkboxField: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
    productCheckbox: {
        display: "flex",
        gap: 6,
        "& .css-view-1dbjc4n": {
            borderColor: "#005e95",
        },
        "& .r-backgroundColor-11udlyb": {
            backgroundColor: "#005e95",
        },
    },
    productNameField: {
        display: "flex",
        alignItems: "center",
        gap: 24,
        "& .MuiInputBase-root": {
            height: 56,
        },
        "& .MuiInputBase-input": {
            border: "unset !important",
        },
        width: "100%",
        marginBottom:"15px"
    },
    formGroupContainer: {
        width: "60%",
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
    },
    errorMsg: {
        color: "red"
    },
    actionBtnStyle: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 8
    },
    tabsContainer: {
        paddingLeft: "0 !important",
        "& .MuiTab-root": {
            textTransform: "none",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#005e95",
        },
        "& .Mui-selected": {
            fontWeight: "bold",
            color: "#005e95",
        },
    },
    tabContainer: {
        padding: "0 10px",
        borderBottom: "1px solid rgb(187, 180, 180)",

    },
    pageHeading: {
        whiteSpace: 'noWrap !important',
        paddingBottom: "12px",
    },
    headingAutocomplete: {
        whiteSpace: 'noWrap !important',
        paddingBottom: "6px",
    },
    saveBtn: {
        backgroundColor: "rgba(9, 65, 115, 0.77)",
        color: "#ffffff"
    },
    checkboxDiv: {
        display: "flex",
        alignItems: "center"
    },
    addMedia: {
        width: 100,
        height: 100,
        borderRadius: 6
    },
    errorBox: {
        display: 'flex',
        alignItems: 'center'
    },
    imageStyle: {
        width: "25px",
        paddingTop: "15px"
    },
    alertIcon: {
        width: "10px",
        position: "absolute"
    },
    errorTextMsg: {
        color: 'red',
        marginLeft: "15px"
    },
};
const ProductTopDetails = styled(Box)({
    display: "flex",
    alignItems: "center",
    padding: '12px',
    marginBottom: "40px",
    marginTop: "40px",
})

const ProductTopSubDetails = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: '100%'
})

const ProductTopSubDetailsBox = styled(Box)({
    width: "50%",
})

const ProductNameTextContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    padding: "16px",
})
const FormControlLabelContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
})

const AddMediaContainer = styled(Box)({
    marginRight:"60px"
})
const LoaderContainer = styled(Box)({
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '40vh',
})
const LoaderContainer2 = styled(Box)({
    margin:'auto',
})

// Customizable Area End
